<template>
    <div >
      <el-row>
            <div style="float: right;">
                <el-select v-model="listQuery.serviceId" size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="emspServiceList.length>0">
                  <el-option key="0" label="全部應用服務商" value="0"></el-option>  
                  <el-option v-for="item in emspServiceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
                </el-select>
                <el-select v-model="listQuery.cpoServiceId" size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="cpoServiceList.length>0">
                  <el-option key="0" label="全部充電供應商" value="0"></el-option>  
                  <el-option v-for="item in cpoServiceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
                </el-select>
                <!--
                <el-date-picker v-model="dateRange" size="small" class="filter-item" type="daterange"
                  value-format="yyyy-MM-dd" range-separator="-" :start-placeholder="$t('Start')+$t('Date')"
                  :end-placeholder="$t('End')+$t('Date')">
                </el-date-picker>
                -->
                <el-date-picker
                    v-model="dateStart"
                    :default-vaule="listQuery.startdate"
                    size="small"
                    class="filter-item"            
                    type="date"
                    value-format="yyyy-MM-dd">
                </el-date-picker>             
                <el-date-picker
                    v-model="dateEnd"
                    size="small"
                    class="filter-item"            
                    type="date"
                    value-format="yyyy-MM-dd"
                    :default-vaule="listQuery.enddate">
                </el-date-picker>
                <el-input size="small" v-model="listQuery.name" placeholder="CDR編號" class="filter-item" clearable style="width: 180px;"/>
            </div>
      </el-row>
      <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
        <span >產製時間: {{ reportDate }} </span>
      </el-row>
      <!-- table -->
      <el-table  v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}" :default-sort="defaultSort" @sort-change="logTableSortChange">
        <el-table-column align="center" prop="id" label="編號"  :show-overflow-tooltip="true" sortable="custom" />
        <el-table-column align="center" prop="serviceId" label="充電供應商" sortable="custom"  v-if="cpoServiceList.length > 0">
          <template slot-scope="scope">
            <span :title="scope.row.countryCode + '/'+scope.row.partyId">{{ getCpoServiceName(scope.row.serviceId) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="tokenServiceId" label="應用服務商" sortable="custom"  v-if="emspServiceList.length > 0">
          <template slot-scope="scope">
            <span >{{ getEmspServiceName(scope.row.tokenServiceId) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="startDateTime" label="開始時間"  :show-overflow-tooltip="true" width="180px" sortable="custom" >
          <template slot-scope="scope">
            <span :title="scope.row.timestamp">{{ parseDateTimeToHHMMSS(scope.row.startDateTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="endDateTime" label="結束時間"  :show-overflow-tooltip="true" width="180px" sortable="custom" >
          <template slot-scope="scope">
            <span :title="scope.row.timestamp">{{ parseDateTimeToHHMMSS(scope.row.endDateTime) }}</span>
          </template>
        </el-table-column>
        
        <el-table-column align="center" prop="token" label="使用者資訊" >
            <template slot-scope="scope" style="text-align: left;">
                <div v-if="scope.row.tokenType=='RFID'" style="text-align: left;"><span style="background-color: #eff7fd; border-color: #deeffc; color:#5ab1ef; padding: 2px; font-size: .9em; font-weight: bold;">RFID</span> <span :title="scope.row.tokenUid" style="font-size: .9em;">{{ scope.row.tokenName }}</span></div>
                <div v-else-if="scope.row.tokenType=='APP_USER'" style="text-align: left;"><span style="background-color: #f0f9eb; border-color: #e1f3d8; color:#67c23a; padding: 2px; font-size: .9em; font-weight: bold;">APP</span> <span :title="scope.row.tokenUid" style="font-size: .9em;">{{ scope.row.tokenName }}</span></div>
                <div v-else style="text-align: left;"><span style="background-color: #fdf6ec; border-color: #faecd8; color:#e6a23c; padding: 2px; font-size: .9em; font-weight: bold;">{{ scope.row.tokenType }}</span> <span :title="scope.row.tokenUid" style="font-size: .9em;">{{ scope.row.tokenName }}</span></div>
            </template>
        </el-table-column>
        <el-table-column align="center" label="充電樁#槍">
            <template slot-scope="scope">
            {{ scope.row.locationName + "/" + scope.row.evseId + "#" + scope.row.connectorId }}
            </template>
        </el-table-column>
        <el-table-column align="center" label="充電時間(時)">
            <template slot-scope="scope">
            {{ scope.row.totalTime }}
            </template>
        </el-table-column>
        <el-table-column align="center" label="用電量(kWh)">
            <template slot-scope="scope">
            {{ scope.row.totalEnergy }}
            </template>
        </el-table-column>
        <el-table-column align="center" label="總金額(含稅)">
            <template slot-scope="scope">
            {{ scope.row.totalCostInclVat }}
            </template>
        </el-table-column>
        <el-table-column align="center" prop="valid" title="格式是否正確" label="格式" >
          <template slot-scope="scope" style="text-align: left;">
            <span :title="scope.row.valid" v-if="scope.row.valid">正確</span>
            <span :title="scope.row.responseData" v-else>失敗</span>
          </template>
        </el-table-column>
        <!--<el-table-column align="center" prop="issuer" label="發行單位" />-->
        <el-table-column align="center" prop="lastUpdated" label="建立日期"  :show-overflow-tooltip="true" width="180px" sortable="custom" >
          <template slot-scope="scope">
            <span :title="scope.row.timestamp">{{ parseDateTimeToHHMMSS(scope.row.lastUpdated) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('Operate')" width="70px">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="showDetail(scope.row)" ><svg-icon iconName="view-details" /></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分頁組件-->
      <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
      <CdrDetail :dialogVisible="dialogDetailVisible" :recordData="recordData" @close="dialogDetailOnClose()" />
    </div>
  </template>
  
  <script>
  import Pagination from '@/components/Pagination'
  import { mixins } from '@/views/common/mixins.js'
  import {refreshToken,stopRefreshToken} from "@/utils/auth";
  import {apiUrl} from "@/utils/main";
  import { ColorPicker } from 'element-ui';
  import CdrDetail from './CdrDetail.vue';
  
  export default {
    name: 'Cdr',
    components: {
      Pagination,
      CdrDetail
    },
    mixins: [mixins],
    data() {
      return {
        dialogWidth: 0,
        total: 0,
        logs: [],  // 用來儲存原始 logs 數據
        list: [],
        listLoading: false,
        listQuery: {
          type: "",
          enabled: true,
          serviceId: "0",
          cpoServiceId: "0",
          startdate: undefined,
          enddate: undefined,
          name: undefined,
          item: undefined,
          page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
          size: 15
        },
        defaultSort: { prop: 'timestamp', order: 'descending' },
        dateRange: undefined,
        dateStart: undefined,
        dateEnd:   undefined,
        emspServiceList: [],
        cpoServiceList: [],
        recordData: undefined,
        paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
        reportDate: this.formatCurrentDateTime(),
        dialogDetailVisible: false,
        commandTypeList : [
          {id:"",name:"所有類別"},
          {id:"START_SESSION",name:"開始充電"},
          {id:"STOP_SESSION",name:"停止充電"},
          {id:"RESERVE_NOW",name:"預約充電"},
          {id:"CANCEL_RESERVATION",name:"取消預約"},
          {id:"UNLOCK_CONNECTOR",name:"解鎖充電槍"}
        ]
      };
    },
    watch: {
      paginationSelectedPage: {
        handler: function(newValue) {
          this.listQuery.page = newValue - 1
        }
      },
      'listQuery.serviceId': function () {
            this.listQuery.page = 0
            this.resetPage(this.getList)
      },
      'listQuery.cpoServiceId': function () {
            this.listQuery.page = 0
            this.resetPage(this.getList)
      },
      'listQuery.type': function() {
        this.listQuery.page = 0
        this.resetPage(this.getList)
      },
      'listQuery.name': function() {
        this.listQuery.page = 0
        this.resetPage(this.getList)
      },
      'listQuery.item': function() {
        this.listQuery.page = 0
        this.resetPage(this.getList)
      },
      /*
      dateRange: function(val) {
        if (val) {
          let sdt = this.$moment(this.dateRange[0]).tz('Asia/Taipei').format() // 轉為台灣時區
          let edt = this.$moment(this.dateRange[1]).tz('Asia/Taipei').format() // 轉為台灣時區
          sdt = sdt.substring(0,19) + 'Z'
          edt = edt.substring(0,19) + 'Z'
          console.log("change dateRange: " + sdt + " - " + edt)
          this.listQuery.startdate = sdt
          this.listQuery.enddate = edt
          this.resetPage(this.getList)
        } else {
          this.listQuery.startdate = undefined
          this.listQuery.enddate = undefined
          // 未輸入
          this.$message({
              message: "請輸入時間範圍",
                type: 'error'
          })
        }        
      },*/
      dateStart: function(val) {
        if (val) {
          this.listQuery.startdate = this.dateStart
        } else {
          this.listQuery.startdate = undefined
        }
          this.listAll= [];
          this.listQuery.page = 0
          this.resetPage(this.getList)
      },   
      dateEnd: function(val) {
        if (val) {
          this.listQuery.enddate = this.dateEnd
        } else {
          this.listQuery.enddate = undefined
        }
          this.listAll= [];
          this.listQuery.page = 0
          this.resetPage(this.getList)
      },
    },
    async created() {
      let date = new Date()
      let daysAgo = new Date()

      daysAgo = daysAgo.setDate(daysAgo.getDate()-7)
      this.listQuery.startdate = this.parseDateTime(daysAgo)
      this.dateStart = this.parseDateTime(daysAgo)
      this.dateEnd = this.parseDateTime(date)
      this.listQuery.enddate = this.parseDateTime(date)

      this.dialogWidth = this.setDialogWidth()
      this.getEmspServiceOptions()
      this.getCpoServiceOptions()
      
      //上面設定日期後觸發 getList 動作
      //this.resetPage(this.getList)
    },
    beforeDestroy(){
      console.log("call beforeDestroy");
      //stopRefreshToken();
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.dialogWidth = this.setDialogWidth()
        })()
      }
    },
    methods: {
      async getList() {
        this.listLoading = true
        this.axios.get('/api/v1/ocpi/cdr', { params: this.listQuery }).then(res => {
            this.list = res.data.content
            // console.log(this.list)
            this.total = res.data.totalElements
            this.listLoading = false

            this.reportDate = this.formatCurrentDateTime()
        })
      },
      getEmspServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/emsp/enabled').then(res => {
            this.emspServiceList = res.data
        })
      },
      getCpoServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/cpo/enabled').then(res => {
            this.cpoServiceList = res.data
        })
      },
      logTableSortChange(column) { // 自定義排序方式
        this.listLoading = true
        this.list = []
        if (column.prop.indexOf('profile.') != -1) {
          column.prop = column.prop.replace('profile.','')
        }

        //console.log("logTableSortChange: " + column.prop);
        if (column.order === 'descending') {
          if(column.prop === 'timestamp') {
           
            this.logs.sort(function(a, b){return b.timestamp - a.timestamp})
            //console.log(this.logs.length);
          } else {
            this.logs.sort(function(a, b){
              if (a[column.prop] > b[column.prop]) {
                return -1;
              }
              if (b[column.prop] > a[column.prop]) {
                return 1;
              }
              return 0;
            })
          }
          //this.listQuery.sort = column.prop + ',' + 'DESC'
        } else {
          if(column.prop === 'timestamp') {
            
            this.logs.sort(function(a, b){return a.timestamp - b.timestamp})
          } else {
            this.logs.sort(function(a, b){
              if (a[column.prop] > b[column.prop]) {
                return 1;
              }
              if (b[column.prop] > a[column.prop]) {
                return -1;
              }
              return 0;
            })
          }

          
        }
        console.log(this.logs.length);
          for(let i=0,  p = this.listQuery.page*this.listQuery.size; p < (this.listQuery.page+1)*this.listQuery.size && p < this.total; p++, i++ ) {
            this.list[i] = this.logs[p]
          }
          this.listLoading = false
      },
      getEmspServiceName(sid) {
        let name = "系統"
        this.emspServiceList.forEach(item => {
          if(item.id == sid) {
            name= item.name
          }})
        return name
      },
      getCpoServiceName(sid) {
        let name = "系統"
        this.cpoServiceList.forEach(item => {
          if(item.id == sid) {
            name= item.name
          }})
        return name
      },
      formatCommandType(type) {
        if(type != undefined) {
          if(type == "START_SESSION") type = "開始充電"
          else if(type == "STOP_SESSION") type = "停止充電"
          else if(type == "RESERVE_NOW") type = "預約充電"
          else if(type == "CANCEL_RESERVATION") type = "取消預約"
          else if(type == "UNLOCK_CONNECTOR") type = "解鎖充電槍"         
        }
        return type;
      },
      formatResponseType(type) {
        if(type != undefined) {
          if(type == "ACCEPTED") type = "服務已接受"
          else if(type == "REJECTED") type = "服務已拒絕"
          else if(type == "NOT_SUPPORTED") type = "服務不支援"
          else if(type == "UNKNOWN_SESSION") type = "未知充電會話"
        }
        return type;
      },
      formatResultType(type) {
        if(type != undefined) {
          if(type == "ACCEPTED") type = "充電樁已接受"
          else if(type == "REJECTED") type = "充電樁拒絕執行"
          else if(type == "FAILED") type = "充電樁執行失敗"
          else if(type == "NOT_SUPPORTED") type = "充電樁不支援"
          else if(type == "EVSE_INOPERATIVE") type = "充電樁無法運作或故障"
          else if(type == "EVSE_OCCUPIED") type = "充電樁已被使用中"
          else if(type == "TIMEOUT") type = "控制已逾時"
          else if(type == "CANCELED_RESERVATION") type = "預約已被服務取消"
          else if(type == "UNKNOWN_RESERVATION") type = "未知的預約訂單"
        }
        return type;
      },
      showDetail(row) {
        console.log("showDetail")
        this.dialogDetailVisible = true
        this.recordData = row
      },
      dialogDetailOnClose() {
        this.dialogDetailVisible = false
        this.recordData = undefined
      },
    }
  }
  </script>
  