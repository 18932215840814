<template>
  <el-dialog width="720px" title="充電明細紀錄" :visible="dialogVisible" @close="dialogDataOnClose()"
    style="padding-top: 10px;">
    <el-tabs v-model="activeName" class="record-tabs" @tab-click="handleClick">
      <el-tab-pane v-loading="cdrloading" label="充電明細" name="cdr" style="padding: 0px 10px;">
        <!-- CDR -->
        <el-row :gutter="10" style="font-size:1em; font-weight: bold;">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: left;">
            充電明細編號: {{ cdrData.id }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: right;">
            建立日期: {{ cdrData.lastUpdated }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電明細提供商: </span>{{ cdrData.serviceName }} ({{ cdrData.countryCode }}-{{
            cdrData.partyId }})
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;"
            v-if="cdrData.credit != null && cdrData.credit == true">
            <span class="data-label">Credit CDR ID: </span>{{ cdrData.creditReferenceId }}
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-divider content-position="left">充電使用者資訊</el-divider>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">營運商: </span>{{ cdrData.tokenServiceId }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">類別: </span>{{ cdrData.tokenType }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">用戶名稱: </span>{{ cdrData.tokenUid }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">合約編號: </span>{{ cdrData.tokenContractId }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">認證方式: </span>{{ formatAuthMethod(cdrData.authMethod) }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;"
            v-if="cdrData.authReference != null">
            <span class="data-label">認證資訊: </span>{{ formatAuthMethod(cdrData.authReference) }}
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-divider content-position="left">充電明細</el-divider>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left;">
            <span class="data-label">充電時間: </span>{{ cdrData.startDateTime }} ~ {{ cdrData.endDateTime }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left;">
            <span class="data-label">充電會話編號: </span>{{ cdrData.sessionId }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電站: </span>{{ cdrData.locationName }} ({{ cdrData.locationId }})
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電站地址: </span>{{ formatCdrLocation(cdrData.cdrLocation) }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電樁: </span>{{ cdrData.evseId }} ({{ cdrData.evseUid }})
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電槍編號: </span>#{{ cdrData.connectorId }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;"
            v-if="cdrData.metterId != null">
            <span class="data-label">電錶編號: </span>${{ cdrData.metterId }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">總充電時間: </span>{{ cdrData.totalTime }} (時)
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">總充電度數: </span>{{ cdrData.totalEnergy }} (kWh)
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">總費用: </span>$ {{ cdrData.totalCostExclVat }} &nbsp; (含稅 $ {{
            cdrData.totalCostInclVat }} )
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">總費用: </span>$ {{ cdrData.totalCostExclVat }} &nbsp; (含稅 $ {{
            cdrData.totalCostInclVat }} )
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;"
            v-if="cdrData.remark != null">
            <span class="data-label">備註: </span>{{ cdrData.remark }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">發票參考編號: </span>{{ cdrData.invoiceReferenceId }}
          </el-col>
        </el-row>
        <el-divider content-position="left">費用明細</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span title="排除停車和預訂固定價格部分的所有固定成本的總和。成本不取決於所使用的時間/能源量等。" class="data-label">固定費用(含稅): </span>${{
            cdrData.totalFixedCostExclVat }} &nbsp; (含稅 $ {{ cdrData.totalFixedCostInclVat }} )
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電度數: </span>{{ cdrData.totalEnergy }} (kWh)
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span title="所有使用能源的成本總和。" class="data-label">充電用量費用(含稅): </span>$ {{ cdrData.totalEnergyCostExclVat }}
            &nbsp; (含稅 $ {{ cdrData.totalEnergyCostInclVat }} )
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電時間: </span>{{ cdrData.totalTime }} (時)
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span title="這筆交易中與充電時間相關的所有費用總和" class="data-label">充電時間費用(含稅): </span>$ {{ cdrData.totalTimeCostExclVat }}
            &nbsp; (含稅 $ {{ cdrData.totalTimeCostInclVat }} )
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">停車時間: </span> {{ cdrData.totalParkingTime }} (時)
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span title="這筆交易中與停車相關的所有費用總和，包括固定價格組成部分" class="data-label">停車費用(含稅): </span>$ {{
            cdrData.totalParkingCostExclVat }} &nbsp; (含稅 $ {{ cdrData.totalParkingCostInclVat }} )
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span title="這筆交易中與預約充電樁相關的所有費用總和，包括固定價格組成部分" class="data-label">預約費用(含稅): </span>$ {{
            cdrData.totalReservationCostExclVat }} &nbsp; (含稅 $ {{ cdrData.totalReservationCostInclVat }} )
          </el-col>
        </el-row>
        <el-divider content-position="left">充電歷程</el-divider>
        <el-row :gutter="20">
          <el-timeline style="max-width: 300px; text-align: left; margin-left: 25px;">
            <el-timeline-item v-for="(activity, index) in periods" :key="index" :icon="activity.icon"
              :type="activity.type" :color="activity.color" :timestamp="activity.timestamp">
              <div v-for="(dct, idx) in activity.content" :key="idx">
                {{ dct.type }} <span v-if="dct.value != null">&nbsp;:&nbsp;{{ dct.value }}</span>
              </div>
            </el-timeline-item>
          </el-timeline>
        </el-row>
        <el-divider content-position="left">費率資訊</el-divider>
        <el-row :gutter="20">
          <el-table :data="tariffs" border style="width: 90%; margin: 0px auto; "
            :cell-style="{padding: '0', height: '32px'}">
            <el-table-column align="center" prop="type" label="類型" />
            <el-table-column align="center" prop="price" label="費用">
              <template slot-scope="scope">
                $ {{ scope.row.price }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="vat" label="稅率%">
              <template slot-scope="scope">
                {{ scope.row.vat }} %
              </template>
            </el-table-column>
            <el-table-column align="center" prop="step_size" label="計算規則" />
          </el-table>
        </el-row>
      </el-tab-pane>
      <!-- End CDR -->


      <!-- 充電會話 -->
      <el-tab-pane v-loading="chargingloading" label="充電會話" name="charging" style="padding: 0px 10px;">
        <el-row :gutter="10" style="font-size:1em; font-weight: bold;">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: left;">
            充電序號: {{ chargingData.id }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: right;">
            建立日期: {{ chargingData.createTime }}
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電站: </span>{{ chargingData.stationName }} ({{ chargingData.stationId }})
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電站地址: </span>{{ chargingData.stationAddress }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電樁: </span>{{ chargingData.chargePointName }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電槍編號: </span>#{{ chargingData.connectorId }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">總充電時間: </span>{{ diffTime(chargingData.stopTime, chargingData.startTime) }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">總充電度數: </span>{{ diffMetter(chargingData.meterStop, chargingData.meterStart) }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電狀態: </span>
            <el-tag type="success" size="small" effect="dark" v-if="chargingData.status == 'completed'">充電完成</el-tag>
            <el-tag type="danger" size="small" effect="dark"
              v-else-if="chargingData.status != undefined && chargingData.status.startsWith('fail')">{{
              formatChargingStatus(chargingData.status) }}</el-tag>
            <el-tag type="info" size="small" effect="dark" v-else>{{ formatChargingStatus(chargingData.status)
              }}</el-tag>
          </el-col>
        </el-row>
        <el-divider content-position="left">充電明細</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電交易類型: </span>{{ formatAccountType(chargingData.accountType) }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">類型描述: </span>{{
            chargingData.accountType == "RFID" ? chargingData.accountValueName + "(" + chargingData.accountValue + ")" :
            chargingData.accountValue
            }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">開始時間: </span>{{ chargingData.startTime }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">結束時間: </span>{{ chargingData.stopTime }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">電錶開始度數: </span>{{ chargingData.meterStart }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">電錶結束度數: </span>{{ chargingData.meterStop }}
          </el-col>
        </el-row>
        <el-divider content-position="left">OCPP 充電交易資訊</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label" style="width: 140px;">交易ID: </span>{{ chargingData.ocppTransactionId }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label" style="width: 140px;">OCPP開始時間: </span>{{ chargingData.ocppStartTime }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label" style="width: 140px;">OCPP結束時間: </span>{{ chargingData.ocppStopTime }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label" style="width: 140px;">OCPP電錶開始度數: </span>{{ chargingData.ocppMeterStart }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label" style="width: 140px;">OCPP電錶結束度數: </span>{{ chargingData.ocppMeterStop }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label" style="width: 140px;">交易結束原因: </span>{{ chargingData.ocppTransactionStopReason }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label" style="width: 140px;">遠端交易ID標籤: </span>{{
            chargingData.ocppRemoteStartTransactionIdTag }}
          </el-col>
        </el-row>
      </el-tab-pane>
      <!-- End 充電紀錄 -->


      <!-- 交易紀錄 -->
      <el-tab-pane v-loading="paymentloading" label="支付明細" name="payment" style="padding: 0px 10px;">
        <div v-if="nopayment">
          <el-row :gutter="10" style="font-size:1em; font-weight: bold;">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: center; padding-top: 30px;">
              無交易紀錄
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-row :gutter="10" style="font-size:1em; font-weight: bold;">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: left;">
              交易編號: {{ paymentData.id }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: right;">
              建立日期: {{ paymentData.createTime }}
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">金流平台: </span>{{ paymentData.paymentPlatform }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">金流平台交易ID: </span><el-link type="success" @click="showTradeDetail()"> {{
                paymentData.paymentTradeId }} </el-link>&nbsp;
              <!--Modified by Lillian (20240603): payment_records之status:
                  //OLD: -1=交易未完成, 0=交易(請款)成功, 1=待請款, 2=退款中, 3=退款完成, 4=退款失敗, 5=補繳中, 6=補繳完成, 7=補繳失敗, 8=交易失敗(系統錯誤).
                  //NEW: -1=交易未完成, 0=交易(請款)完成, 1=請款中, 2=退款中, 3=退款完成, 4=退款失敗, 5=補繳中, 9=付款失敗 (Modified by Lillian_20240822)
                  -->
              <el-tag type="success" size="small" effect="plain" v-if="paymentData.status == 0">交易成功</el-tag>
              <el-tag type="danger" size="small" effect="plain" v-else-if="paymentData.status == -1">交易未完成</el-tag>
              <el-tag type="primary" size="small" effect="plain" v-else-if="paymentData.status == 1">請款中</el-tag>
              <el-tag type="danger" size="small" effect="plain" v-else-if="paymentData.status == 2">退款中</el-tag>
              <el-tag type="danger" size="small" effect="plain" v-else-if="paymentData.status == 3">退款完成</el-tag>
              <el-tag type="danger" size="small" effect="plain" v-else-if="paymentData.status == 4">退款失敗</el-tag>
              <el-tag type="warning" size="small" effect="plain" v-else-if="paymentData.status == 5">補繳中</el-tag>
              <el-tag type="danger" size="small" effect="plain" v-else>交易失敗({{ paymentData.msg }})</el-tag>
            </el-col>
          </el-row>
          <el-divider content-position="left">交易內容</el-divider>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">充電站: </span>{{ paymentData.stationName }} ({{ paymentData.stationAddress }})
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">開始充電時間: </span>{{ paymentData.chargingStartTime }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">結束充電時間: </span>{{ paymentData.chargingStopTime }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">總充電時間: </span>{{ formatSecond(paymentData.totalChargingTime) }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">總充電度數: </span>{{ paymentData.totalChargingMeter }}
            </el-col>
          </el-row>

          <el-divider content-position="left">付款資訊</el-divider>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">會員: </span>
              <el-tooltip class="item" effect="dark" :content="paymentData.appAccountUser" placement="top-start"
                v-if="paymentData.appAccountUser != null">
                <span v-if="paymentData.appAccountUser != null">{{
                  paymentData.appAccountUser.replace(paymentData.appAccountUser.substr(6, 4), "****") }}</span>
              </el-tooltip>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">會員姓名: </span>
              <el-tooltip v-if="paymentData.appAccountName != null" class="item" effect="dark"
                :content="paymentData.appAccountName" placement="top-start">
                <span v-if="paymentData.appAccountName != null">{{ hideName(paymentData.appAccountName) }}</span>
              </el-tooltip>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">付款方式: </span>{{ paymentData.cardIssuer }} ****{{ paymentData.cardLastFour }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">資費: </span>{{ paymentData.rate }}
              <!-- Added by Lillian (20241011)
              //price_type=1(固定費率), rate_type=1(免費)
              //price_type=1(固定費率), rate_type=2(計度)
              //price_type=1(固定費率), rate_type=3(計分)
              -->
              <el-tag type="success" size="small" effect="plain"
                v-if="paymentData.priceType == 1 && paymentData.rateType == 1"> 免費</el-tag>
              <el-tag type="success" size="small" effect="plain"
                v-if="paymentData.priceType == 1 && paymentData.rateType == 2"> 以度計費</el-tag>
              <el-tag type="success" size="small" effect="plain"
                v-if="paymentData.priceType == 1 && paymentData.rateType == 3"> 以分計費</el-tag>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">總費用: </span><span class="data-amount">{{ paymentData.amount }}</span>
            </el-col>
          </el-row>

          <el-divider content-position="left">發票資訊</el-divider>
          <el-row :gutter="20" v-if="paymentData.invoiceSetting == -1">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span>無發票資訊</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-else>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <!-- Modified by Lillian (20240913)
              <span class="data-label">發票設定: </span>{{ formatInvoiceSetting(paymentData.invoiceSetting) }}
              -->
              <span class="data-label">發票設定: </span>{{ formatNewInvoiceSetting(paymentData.carrierType) }}{{
              paymentData.carrierData }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">開立發票結果: </span>{{ paymentData.recInvoiceId }}
              <el-tag type="success" size="small" effect="plain" v-if="paymentData.invoiceStatus == 0">成功</el-tag>
              <el-tag type="warning" size="small" effect="plain"
                v-else-if="paymentData.invoiceStatus == -1">未開立</el-tag>
              <el-tag type="success" size="small" effect="plain"
                v-else-if="paymentData.invoiceStatus == 1">開立中</el-tag><!--Added by Lillian (20240828)-->
              <el-tag type="danger" size="small" effect="plain"
                v-else-if="paymentData.invoiceStatus == 2">開立失敗</el-tag><!--Added by Lillian (20240914)-->
              <el-tag type="success" size="small" effect="plain" v-else>已作廢({{ paymentData.invoiceMsg }})</el-tag>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">發票號碼: </span> {{ paymentData.invoiceNumber }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
              <span class="data-label">開立時間: </span>{{ paymentData.invoiceDate }}
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <!-- End 交易紀錄 -->

    </el-tabs>
    <TradeDetail :tradeDialogVisible="tradeDialogDetailVisible" :paymentRecordData="paymentRecordData"
      @close="tradeDialogOnClose()" append-to-body />

    <div class="dialog-footer" style="text-align: center; margin-top: 26px;">
      <el-button @click="dialogDataOnClose()">關閉</el-button>
    </div>

  </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import TradeDetail from '../trade/TradeDetail.vue'
export default {
  name: 'CdrDetail',
  components: {
    TradeDetail
  },
  mixins: [mixins],
  props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
    recordData: {
      require: false,
      default: undefined,
      type: Object
    },
    dialogVisible: {
      require: true,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      cdrData:{},
      activeName: "cdr",
      dialogWidth: 0,
      paymentData: {},
      paymentRecordData: undefined,
      chargingData: {},
      cdrloading: false,
      chargingloading: false,
      paymentloading: false,
      nopayment: false,
      tradeDialogDetailVisible: false,
      tariffs: [],
      periods : [],
    };
  },
  watch: {
    recordData(val) {
      if (val) {
        this.cdrData = val
        
        let chargingSessionId = val.sessionId
        let paymentId = val.paymentRecordId
        this.formatTariffs(val.tariffs)
        this.formatChargingPeriods(val.chargingPeriods, val.startDateTime, val.endDateTime)
        if(paymentId == "" || paymentId == null ) {
          this.nopayment = true
        } else {
          this.getPaymentRecord(paymentId)
        }
        this.getChargingSessionRecord(val.serviceId+"_"+chargingSessionId)
        
      }
    },
  },
  async created() {
    this.dialogWidth = this.setDialogWidth()
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getPaymentRecord(id) {
      this.paymentloading = true
      this.axios.get('/api/v1/payment/record/'+id).then(res => {
        this.paymentData = res.data
        this.paymentloading = false
      })
    },
    getChargingSessionRecord(id) {
      this.chargingloading = true
      this.axios.get('/api/v1/charging/record/' + id).then(res => {
        this.chargingData = res.data
        this.chargingloading = false
      })
    },
    dialogDataOnClose() {
      this.tariffs = []
      this.periods = []
      this.cdrData = {}
      this.paymentData = {}
      this.chargingData = {}
      this.nopayment = false
      this.$emit('close') // 觸發父層 close
    },
    tradeDialogOnClose() {
      
      this.tradeDialogDetailVisible = false
      this.paymentRecordData = undefined
    },
    handleClick(tab, event) { // = (tab: TabsPaneContext, event: Event) => {
      console.log(tab, event)
    },
    showTradeDetail(){
      this.tradeDialogDetailVisible = true
      this.paymentRecordData = this.paymentData
    },
    formatInvoiceSetting(setting) {
      // 0_1SDGFJKL1  _分隔 ， 前面為類型，後面為值
      if(setting != undefined) {
        const invoices = setting.split(/_(.*)/s)
        if(invoices.length >= 1) {
          if(invoices[0] == 0) setting = "手機載具"
          else if(invoices[0] == 1) setting = "自然人憑證"
          else if(invoices[0] == 2) setting = "捐贈發票"
          else if(invoices[0] == 3) setting = "雲端會員"
          else if(invoices[0] == 4) setting = "公司名稱/統編"
          else if(invoices[0] == 5) setting = "中華電信會員載具" //Added by Lillian (20240830)-身分證號碼
          else if(invoices[0] == -1) setting = "無"

          if(invoices.length > 1) {
            setting = setting + ": " + invoices[1]
          }
        } 
      }
      return setting;
    },
    formatNewInvoiceSetting(carrierType) {
      if(carrierType != undefined) {
        if(carrierType == 0) carrierType = "手機載具: "
        else if(carrierType == 1) carrierType = "自然人憑證: "
        else if(carrierType == 2) carrierType = "捐贈發票: "
        else if(carrierType == 3) carrierType = "雲端會員: "
        else if(carrierType == 4) carrierType = "公司統編: "
        else if(carrierType == 5) carrierType = "中華電信會員載具: " //Added by Lillian (20240830)-身分證號碼
        else if(carrierType == -1) carrierType = "無資料"
      }
      return carrierType;
    },
    formatAccountType(type) {
      if(type == "sys") type = "系內部測試"
      else if(type == "app") type = "APP"
      else if(type == "web") type = "網站"
      return type
    },
    formatCdrLocation(cdrLocation) {
      if(cdrLocation!=undefined) {
        const locObj = JSON.parse(cdrLocation);
        return locObj.city +""+ locObj.address;
      }
      return ""
    },
    formatChargingPeriods(periodStr, startDateTime, endDateTime){
      this.periods.push({
            content: [{type:'開始充電流程',value:null}],
            timestamp: startDateTime,
            //type: 'primary',
            //color: '#0bbd87',
          })
      if(periodStr != undefined) {
        const periodsObj = JSON.parse(periodStr)
        periodsObj.forEach((period) =>{
          let content = "";
          let value = "";
          let contents = []
          period.dimensions.forEach((dimension) => {
            contents.push({
              type: this.formatCdrDimensionType(dimension.type),
              value: dimension.volume + this.formatCdrDimensionTypeUnit(dimension.type)
            })
            /*
            if(content.length > 0) { 
              content = content + ' / ';
              value = value + ' / ';
            }
            content = content + dimension.type;
            value = value + dimension.volume.toString();*/
          });
          let pobj = {
            //content: content,
            content: contents,
            timestamp: this.parseDateTimeToHHMMSSWithoutTz(period.start_date_time),
            value: value,
            type: 'primary',
            //color: '#0bbd87',
          }
          this.periods.push(pobj)
        });
        
      }
      this.periods.push({
            content: [{type:'完成充電流程',value:null}],
            timestamp: endDateTime,
            //type: 'primary',
            //color: '#0bbd87',
          })
    },
    formatCdrDimensionType(dimensionType) {
      if(dimensionType == "CURRENT") {
        dimensionType = "平均電流"
      } else if(dimensionType == "ENERGY") {
        dimensionType = "總充電量"
      } else if(dimensionType == "ENERGY_EXPORT") {
        dimensionType = "回饋電網電量"
      } else if(dimensionType == "ENERGY_IMPORT") {
        dimensionType = "充入電動車電量"
      } else if(dimensionType == "MAX_CURRENT") {
        dimensionType = "最大電流"
      } else if(dimensionType == "MIN_CURRENT") {
        dimensionType = "最小電流"
      } else if(dimensionType == "MAX_POWER") {
        dimensionType = "最大功率"
      } else if(dimensionType == "MIN_POWER") {
        dimensionType = "最小功率"
      } else if(dimensionType == "PARKING_TIME") {
        dimensionType = "停車時間"
      } else if(dimensionType == "POWER") {
        dimensionType = "平均功率"
      } else if(dimensionType == "RESERVATION_TIME") {
        dimensionType = "預約保留時間"
      } else if(dimensionType == "STATE_OF_CHARGE") {
        dimensionType = "充電進度"
      } else if(dimensionType == "TIME") {
        dimensionType = "充電時間"
      } 
      return dimensionType
    },
    formatCdrDimensionTypeUnit(dimensionType) {
      let unit = ""
      if(["CURRENT", "MAX_CURRENT", "MIN_CURRENT"].includes(dimensionType)) {
        unit = " A"
      } else if(["POWER", "MAX_POWER", "MIN_POWER"].includes(dimensionType)) {
        unit = " kW"
      } else if(["ENERGY", "ENERGY_EXPORT", "ENERGY_IMPORT"].includes(dimensionType)) {
        unit = " kWh"
      } else if(["PARKING_TIME", "RESERVATION_TIME", "TIME"].includes(dimensionType)) {
        unit = " hr"
      } else if(dimensionType == "STATE_OF_CHARGE") {
        unit = " %"
      } 
      return unit
    },
    formatTariffs(tariffStr){
      if(tariffStr != undefined) {
        const tariffsObj = JSON.parse(tariffStr)
        tariffsObj.forEach((tariffObj)=>{
          tariffObj.elements.forEach((element) =>{
            element.price_components.forEach((component) =>{
              this.tariffs.push(component)
            })
          })
        });
      }
    },
    formatAuthMethod(authMethod) {
      if(authMethod == "WHITELIST") {
        authMethod = "白名單(WHITE)"
      } else if(authMethod == "AUTH_REQUEST") {
        authMethod = "認證請求(AUTH REQUEST)"
      } else if(authMethod == "COMMAND") {
        authMethod = "控制指令(COMMAND)"
      }
      return authMethod
    },
    doAgreeRefund(paymentId) { // [同意退款]
      const refundModel = {
        paymentId: paymentId
      };
      this.axios.put('/api/v1/payment/agreeRefund', refundModel).then(()=> {
        this.$message({
          showClose: true,
          message: "OK",
          type: 'success'
        })
        this.dialogDataOnClose() //close the window
        window.location.reload() //refresh the result
      }).catch(err => {
        console.log('err:' + err)
        if(err.data.includes('NotAdmin')) {
          this.$message({
            message: this.$t('NotAdmin'),
            type: 'error'
          })
        } else if(err.data.includes('OldRecord')) {
          this.$message({
            message: this.$t('OldRecord'),
            type: 'error'
          })
        } else{
          this.$message({
            message: "Fail",
            type: 'error'
          })
        }
      })
      //End--Added by Lillian (20240828)
    },
    doRefund(paymentId){ // [執行退款] Added by Lillian (20240903)
      const refundModel = {
         paymentId: paymentId
      };
      this.axios.put('/api/v1/payment/refund', refundModel).then(()=> {
         this.$message({
           showClose: true,
           message: "OK",
           type: 'success'
         })
         this.dialogDataOnClose() //close the window
         window.location.reload() //refresh the result
      }).catch(err => {
         console.log('err:' + err)
         if(err.data.includes('NotAdmin')) {
           this.$message({
             message: this.$t('NotAdmin'),
             type: 'error'
           })
         } else if(err.data.includes('RefundFail')) {
           this.$message({
             message: this.$t('RefundFail'),
             type: 'error'
           })
         } else if(err.data.includes('OldRecord')) {
           this.$message({
             message: this.$t('OldRecord'),
             type: 'error'
           })
         } else {
           this.$message({
             message: "Fail",
             type: 'error'
           })
         }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.data-label {
  width: 150px; display: inline-block;
}

.data-amount{
  font-size: 1.2em;
  font-weight: bolder;
  color: #409eff;
}
</style>