<template>
    <el-dialog width="680px" title="金流平台交易紀錄明細" :visible="tradeDialogVisible" @close="dialogTradeDataOnClose()" append-to-body style="padding-top: 10px; text-align: center;" >
        <div v-loading="tradeLoading" >
          
          <el-row :gutter="10" style="font-size:1em; font-weight: bold;">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: left;">
              金流平台: {{ paymentPlatform }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: right;">
              建立日期: {{ formatTimestamp(tradeData.time) }}
            </el-col>
          </el-row>
          <el-row :gutter="20" style="font-size:1em; font-weight: bold;">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: left;">
              交易編號: {{ tradeData.rec_trade_id }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: right;">
              商家名稱: {{ (tradeData.merchant_name != undefined && tradeData.merchant_name.length > 0 )? tradeData.merchant_name+'('+tradeData.merchant_id+')':tradeData.merchant_id }}
            </el-col>
          </el-row>
          <el-row :gutter="20" style="font-size:1em; font-weight: bold;">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: left;">
              交易狀態: {{ tappayFormatRecordStatus(tradeData.record_status) }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left;" v-if="tradeData.transaction_method_details != null">
              交易方式: {{ tappayFormatTransactionMethod(tradeData.transaction_method_details) }}
            </el-col>
          </el-row>
          <el-divider content-position="left">交易內容</el-divider>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
                <span class="data-label">付款方式: </span> {{ tappayFormatPaymentMethod(tradeData.payment_method) }} 
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
                <span class="data-label">原始交易金額: </span> {{ tradeData.currency }} {{ tradeData.original_amount }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
                <span class="data-label">退款金額: </span> {{ tradeData.currency }} {{ tradeData.refunded_amount }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
                <span class="data-label">交易金額: </span> {{ tradeData.currency }} {{ tradeData.amount }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
                <span class="data-label">交易明細: </span> {{ tradeData.details }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">請款時間: </span> {{ formatTimestamp(tradeData.cap_millis) }}
            </el-col>
          </el-row>
          <!-- 信用卡看 card_info , LINE PAY 看 pay_info -->
          <el-divider content-position="left" v-if="tradeData.card_info != undefined" >卡片資訊</el-divider>
          <el-row :gutter="20" v-if="tradeData.card_info != undefined" >
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">信用卡識別碼: </span> {{ tradeData.card_identifier }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">信用卡卡號: </span> {{ tradeData.card_info != undefined ? tradeData.card_info.bin_code + " ***** " + tradeData.card_info.last_four: "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">發卡銀行: </span> {{ tradeData.card_info != undefined ? tradeData.card_info.issuer_zh_tw + " (" + tradeData.card_info.issuer + ")": "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">卡片類別: </span> {{ tradeData.card_info != undefined ? tappayFormatCardInfoFunding(tradeData.card_info.funding): "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">卡片種類: </span> {{ tradeData.card_info != undefined ? tappayFormatCardInfoType(tradeData.card_info.type): "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">消費者姓名: </span> {{ tradeData.cardholder != undefined ? tradeData.cardholder.name: "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">消費者電話: </span> {{ tradeData.cardholder != undefined ? tradeData.cardholder.phone_number: "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">消費者信箱: </span> {{ tradeData.cardholder != undefined ? tradeData.cardholder.email: "" }}
            </el-col>
          </el-row>
          <el-divider content-position="left" v-if="tradeData.pay_info != undefined" >支付資訊</el-divider>
          <el-row :gutter="20" v-if="tradeData.pay_info != undefined" >
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;" v-if="tradeData.payment_method == 'line_pay' ">
                <span class="data-label">交易資訊: </span> {{ tappayFormatPayInfoMethod(tradeData.pay_info.method) }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;" v-if="tradeData.pay_info.masked_credit_card_number != ''">
                <span class="data-label">信用卡卡號: </span> {{ tradeData.pay_info.bin_code + " ***** " + tradeData.pay_info.masked_credit_card_number }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">點數折抵金額: </span> {{ tradeData.pay_info.point }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">優惠券折抵金額: </span> {{ tradeData.pay_info.discount }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">信用卡支付金額: </span> {{  tradeData.pay_info.credit_card }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;" v-if="tradeData.pay_info.balance > 0" >
                <span class="data-label">錢包儲值帳號支付金額: </span> {{  tradeData.pay_info.balance }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;" v-if="tradeData.pay_info.bank_account > 0" >
                <span class="data-label">銀行連結帳戶支付金額: </span> {{  tradeData.pay_info.bank_account }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">消費者姓名: </span> {{ tradeData.cardholder != undefined ? tradeData.cardholder.name: "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">消費者電話: </span> {{ tradeData.cardholder != undefined ? tradeData.cardholder.phone_number: "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">消費者信箱: </span> {{ tradeData.cardholder != undefined ? tradeData.cardholder.email: "" }}
            </el-col>
          </el-row>
          <el-divider content-position="left">銀行交易資訊</el-divider>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">銀行授權碼: </span> {{ tradeData.auth_code }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">訂單編號(與銀行對帳使用): </span> {{ tradeData.bank_transaction_id }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">是否已請款: </span> {{ formatTrueOrFalse(tradeData.is_captured) }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">銀行實際交易時間: </span> {{ formatTimestamp(tradeData.bank_transaction_start_millis) }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">銀行實際結束時間: </span> {{ formatTimestamp(tradeData.bank_transaction_end_millis) }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">銀行回傳交易結果: </span> {{ tradeData.bank_result_code }} {{ tradeData.bank_result_msg }}
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">是否為 3D 驗證交易: </span> {{ formatTrueOrFalse(tradeData.three_domain_secure) }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">是否取得 RBA 交易風險評估: </span> {{ formatTrueOrFalse(tradeData.is_rba_verified) }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">            
            </el-col>
          </el-row>
          <el-divider content-position="left">電子發票載具資訊</el-divider>
          <el-row :gutter="20" v-if="tradeData.e_invoice_carrier != undefined">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">電子發票載具類別: </span> {{ tradeData.e_invoice_carrier != undefined ? tradeData.e_invoice_carrier.type: "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">電子發票載具號碼: </span> {{ tradeData.e_invoice_carrier != undefined ? tradeData.e_invoice_carrier.number: "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;">
                <span class="data-label">是否捐贈: </span> {{ tradeData.e_invoice_carrier != undefined ? formatTrueOrFalse(tradeData.e_invoice_carrier.donation): "" }}
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px; vertical-align: middle;" v-if="tradeData.e_invoice_carrier != undefined && tradeData.e_invoice_carrier.donation == true">
                <span class="data-label">愛心碼: </span> {{ tradeData.e_invoice_carrier != undefined ? tradeData.e_invoice_carrier.donation_id: "" }}
            </el-col>
          </el-row>
          <el-row :gutter="20" v-else>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;" >
              <span >無電子發票載具資訊</span>
            </el-col>
          </el-row>
          
        </div>
        <template #footer>
            <div class="dialog-footer" style="text-align: center;">
                <el-button @click="dialogTradeDataOnClose()">關閉</el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import { mixins } from '@/views/common/mixins.js'
import Element from 'element-ui'
export default {
  name: 'TradeDetail',
  components: {

  },
  mixins: [mixins],
  props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
    paymentRecordData: {
      require: false,
      default: undefined,
      type: Object
    },
    tradeDialogVisible: {
      require: true,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      dialogWidth: 0,
      paymentId: undefined,
      paymentPlatform: undefined,
      tradeId: undefined,
      tradeData: {},
      tradeLoading: false
    };
  },
  watch: {
    paymentRecordData(val) {
      if (val) {
        console.log(val)
        this.paymentId = val.id
        this.paymentPlatform = val.paymentPlatform
        this.tradeId = val.paymentTradeId
        this.getTradeRecord(val.paymentPlatform, val.id, val.paymentTradeId)
      }
    }
  },
  async created() {
    this.dialogWidth = this.setDialogWidth()


  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    
    dialogTradeDataOnClose() {
      this.paymentId = undefined
      this.tradeId = undefined
      this.tradeData = {}
      this.$emit('close') // 觸發父層 close
    },
    getTradeRecord(_platform, _id, _tradeId) {
      this.tradeLoading = true
      this.axios.get('/api/v1/payment/record/' + _id + '/'+_platform.toLowerCase()+'/' + _tradeId).then(res => {
        this.tradeData = res.data[0]
        this.tradeLoading = false
      }).catch( error => {
        console.log(error)
        Element.Message({
                  message: error.data.msg,
                  type: "error",
                  duration: 10000,
                  showClose: true
                });
        this.dialogTradeDataOnClose()
      })
    },
    tappayFormatRecordStatus(_status) {
      if(_status == 1) _status = "交易完成" // OK
      else if(_status == 0) _status = "銀行已授權交易，但尚未請款"  // AUTH
      else if(_status == -1) _status = "交易錯誤" // ERROR
      else if(_status == 5) _status = "取消交易" // CANCEL
      else if(_status == 2) _status = "部分退款" // PARTIALREFUNDED
      else if(_status == 3) _status = "完全退款" // REFUNDED
      else if(_status == 4) _status = "待付款" // PENDING
      return _status;
    },
    formatTrueOrFalse(_boolean) {
        return _boolean?"是":"否"
    },
    formatTimestamp(_timestamp) {
        if(_timestamp > 0) {
            let d = new Date(_timestamp);
            let year = d.getFullYear();
            let month = ("0" + (d.getMonth() + 1)).slice(-2); // Month is zero-based
            let day = ("0" + d.getDate()).slice(-2);
            let hour = ("0" + d.getHours()).slice(-2);
            let minute = ("0" + d.getMinutes()).slice(-2);
            let second = ("0" + d.getSeconds()).slice(-2);
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        } 
        return "無"
    },
    tappayFormatPaymentMethod(_method) {
      if(_method == "direct_pay") _method = "Direct Pay" 
      else if(_method == "apple_pay") _method = "Apple Pay" 
      else if(_method == "line_pay") _method = "LINE Pay" 
      else if(_method == "easy_wallet") _method = "悠遊付" 
      else if(_method == "tsp_token") _method = "TSP Token" 
      return _method;
    },
    tappayFormatCardInfoFunding(_funding) {
      if(_funding == 0) _funding = "信用卡" // Credit Card
      else if(_funding == -1) _funding = "Unknown" // ERROR
      else if(_funding == 1) _funding = "簽帳卡" // Debit Card
      else if(_funding == 2) _funding = "預付卡 " // Prepaid Card
      return _funding;
    },
    tappayFormatCardInfoType(_type) {
      if(_type == 1) _type = "VISA" // VISA
      else if(_type == 2) _type = "MasterCard" // MasterCard
      else if(_type == 3) _type = "JCB " // JCB
      else if(_type == 4) _type = "Union Pay " // Union Pay
      else if(_type == 5) _type = "AMEX " // AMEX
      else if(_type == -1) _type = "Unknown" // Unknown
      return _type;
    },
    tappayFormatPayInfoMethod(_method) {
      if(_method == 1) _method = "信用卡(CREDIT_CARD)"
      else if(_method == 2) _method = "一卡通iPASS(BALANCE)"
      else if(_method == 3) _method = "LINE Point 全額折抵(POINT)"
      else if(_method == 4) _method = "LINE Pay 優惠券(DISCOUNT)"
      else _method = "LINE PAY定期定額"
      
      return _method
    },
    tappayFormatTransactionMethod(_method) {
        if(_method != undefined) {
            let transactionMethod = _method.transaction_method + "("+_method.transaction_method_reference+")"
            if(_method.transaction_method == "FRICTIONLESS") {
                transactionMethod = "以一般授權的規格送往銀行做交易"
            } else if(_method.transaction_method == "THREE_DOMAIN_SECURE") {
                transactionMethod = "以 3D 驗證的規格送往銀行做交易"
            }
            if(_method.transaction_method_reference == "REQUEST") {
                transactionMethod = transactionMethod + "，並且，是由商戶交易時帶來的 Request 規格決定交易"
            } else if(_method.transaction_method_reference == "RBA") {
                transactionMethod = transactionMethod + "，並且，是由 RBA 風險分數規則決定交易"
            }  
            return transactionMethod
        }
        return ""
    }
  }
}
</script>
<style lang="scss" scoped>
.data-label {
  width: 200px; display: inline-block;
  padding-right: 10px;
}
.data-amount{
  font-size: 1.2em;
  font-weight: bolder;
  color: #409eff;
}
</style>